<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal id="select-item" title="商品選択" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hidden="onModalHide" hide-footer centered>
      <!-- 一覧 -->
      <div>
        <div style="width: 1200px; ">
          <b-table-filter-pager :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val" :busy="busy"
            :filter_val_ex="filter_val_ex" :data_list_field="data_list_field" :data_list="data_list" :edit_disabled="pms_master_item < 2"
            :data_count="data_count" :currentPage="current_page" @on_sort="onSort" @on_edit='onEdit' @on_add='onEdit'
            @on_page_change='onPageChange' @on_select='onSelect' @on_filter_ex='onFilterEx' :filter_enable="true"
            :nav_flg="true" />
        </div>
      </div>
    </b-modal>
    <!-- モーダル 商品  -->
    <b-modal id="modal-item_edit" :title="title" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" hide-footer centered @hidden="onModalItemEditHide">
      <div class="modal_div">
        <div class="form-inline">
          <label class="block_label">商品コード</label>
          <b-input v-model="item.item_code" type="text" />
        </div>
        <div class="form-inline">
          <label class="block_label">商品分類</label>
          <b-form-select v-model="item.item_type_id" style="width: 180px">
            <option v-for="option in sel_item_type" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form-inline">
          <label class="block_label">発注先</label>
          <b-form-select v-model="item.hattyu_company_id" style="width: 200px">
            <option v-for="option in sel_hattyu_company" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form-inline">
          <label class="block_label">商品名</label>
          <b-input v-model="item.item_name" type="text" style="width: 350px" />
        </div>
        <div class="form-inline">
          <label class="block_label">仕入れ単価</label>
          <b-input v-model="item.hattyu_tanka" type="number" style="width: 150px" />
        </div>
        <div class="form-inline">
          <label class="block_label">単価</label>
          <b-input v-model="item.tanka" type="number" style="width: 150px" />
        </div>
        <div class="form-inline">
          <label class="block_label">税率(%)</label>
          <b-form-select v-model="item.tax_id" style="width: 120px">
            <option v-for="option in sel_tax" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form-inline">
          <label class="block_label">発注区分</label>
          <b-form-select v-model="item.hattyu_cycle_id" style="width: 100px">
            <option v-for="option in sel_hattyu_cycle" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="modal-footer">
        <b-button @click="$bvModal.hide('modal-item_edit')">
          <b-icon icon="x"></b-icon>取消
        </b-button>
        <b-button variant="primary" @click="updateItem">
          <b-icon icon="save"></b-icon>&nbsp;保存
        </b-button>
      </div>
    </b-modal>
    </b-modal>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  // axiosInstance,
  mounted() {
    this.checkLogin();
    this.getItems();
  },
  data: function () {
    return {
      // table
      current_page: 1,
      busy: false,
      isLoading: false,
      sort_by: '',
      sort_desc: true,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_list: [],
      data_count: 0,
      data_list_field: [
        { key: "edit", label: "選択・編集", thStyle: { width: "110px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, select: true, edit: true },
        { key: "item_code", label: "商品コード", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "item_type", label: "商品分類", thStyle: { width: "150px" }, sortable: true, filter: 'select_ex2' },
        { key: "hattyu_company_name", label: "発注先", thStyle: { width: "150px" }, sortable: true, filter: 'select_ex2' },
        { key: "item_name", label: "商品名", thStyle: { width: "250px" }, sortable: true, filter: 'input_ex' },
        { key: "hattyu_tanka", label: "仕入れ単価", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "tanka", label: "単価", thStyle: { width: "100px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "tax", label: "税率", thStyle: { width: "100px" }, tdClass: 'text-right', sortable: true, filter: 'select_ex2' },
        { key: "hattyu_cycle", label: "区分", thStyle: { width: "" }, sortable: true, filter: 'select_ex2' },
      ],
      // modal
      title: '',
      item: [],
      sel_item_type: [],
      sel_hattyu_company: [],
      sel_tax: [],
      sel_hattyu_cycle: [],
      maxHeight: document.documentElement.scrollHeight - 300 + "px"
    }
  },
  methods: {
    //
    onModalHide: function () {
      this.filter_val_ex = new Array(10);
      this.getItems();
    },
    // 商品選択
    onSelect: function (id) {
      this.$emit('on_select', id);
    },
    // 商品一覧 取得
    getItems: function () {
      this.isLoading = false
      axios
        .get('/master_item/get_items/', {
          params: {
            item_code_ex: this.filter_val_ex[1], item_type_id_ex: this.filter_val_ex[2],
            hattyu_company_id_ex: this.filter_val_ex[3], item_name_ex: this.filter_val_ex[4],
            hattyu_tanka_ex: this.filter_val_ex[5], tanka_ex: this.filter_val_ex[6],
            tax_ex: this.filter_val_ex[7], hattyu_cycle_ex: this.filter_val_ex[8],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list = response.data.items_h;
          this.data_count = response.data.item_count;
          this.filter_list[2] = response.data.item_type_filter_list_ex;
          this.filter_list[3] = response.data.hattyu_company_filter_list_ex;
          this.filter_list[7] = response.data.tax_filter_list_ex;
          this.filter_list[8] = response.data.cycle_filter_list_ex;
          this.isLoading = false
        })
    },

    // 商品 モーダル(新規、編集) 表示
    onEdit: function (item_id) {
      if (item_id == -1){
        this.title = "商品 > 追加"
      }else{
        this.title = "商品 > 編集"
      }
      axios
        .get(`/master_item/get_item/${item_id}`)
        .then(response => {
          this.item = response.data.item;
          this.sel_item_type = response.data.sel_item_type;
          this.sel_hattyu_company = response.data.sel_hattyu_company;
          this.sel_tax = response.data.sel_tax;
          this.sel_hattyu_cycle = response.data.sel_hattyu_cycle;
          this.$root.$emit('bv::show::modal', 'modal-item_edit')
        })
    },
    // 商品 新規、編集
    updateItem: function () {
      axios
        .patch(`/master_item/update_item`, { item: this.item })
        .then(response => {
          if (response.data.flg) {
            // this.getItemList();
            this.$root.$emit('bv::hide::modal', 'modal-item_edit')
          } else {
            alert(response.data.msg);
          }
        })
    },
    //
    onModalItemEditHide: function () {
      this.getItems();
    },
    //
    onFilterEx: function () {
      this.getItems();
    },
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getItems();
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getItems();
    }
  }
}
</script>
  
<style scoped></style>