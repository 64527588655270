<template>
  <div>
    <!-- フィルター -->
    <b-table-simple fixed v-if="filter_enable == true" class="filterTB" style="">
      <b-tr>
        <b-th v-for="(list, index) in data_list_field" :key="index"
          :style="'width: ' + data_list_field[index].thStyle.width">
          <template v-if="list.filter == 'select'">
            <b-form-select v-model="filter_val[index]">
              <option></option>
              <option v-for="option in filter_list[index]" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </template>
          <template v-if="list.filter == 'select_ex1'">
            <b-form-select v-model="filter_val_ex[index]" @change="onFilterEx()">
              <option v-for="option in filter_list[index]" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </template>
          <template v-if="list.filter == 'select_ex2'">
            <b-form-select v-model="filter_val_ex[index]" @change="onFilterEx()">
              <option></option>
              <option v-for="option in filter_list[index]" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </template>
          <template v-if="list.filter == 'select_sts'">
            <b-form-select v-model="filter_val[index]">
              <option></option>
              <option v-for="option in filter_list[index]" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </template>
          <template v-else-if="list.filter == 'input'">
            <!-- <b-input debounce="1000" v-model.lazy="filter_val[index]" /> -->
            <b-input lazy v-model.lazy="filter_val[index]" />
          </template>
          <template v-else-if="list.filter == 'input_ex'">
            <!-- <b-input debounce="1000" v-model.lazy="filter_val_ex[index]"  /> -->
            <b-input lazy v-model.lazy="filter_val_ex[index]" @change="onFilterEx()" />
          </template>
          <template v-else-if="list.filter == 'input_num'">
            <div class="form-inline" style=" width: 116px; margin: 0px; padding: 0px;">
              <b-input lazy v-model.lazy="filter_val[index]" style="width: 60px; margin-left: 0px;" />
              <b-form-select v-model="filter_val_sign[index]" style="width: 50px; margin-left: -2px;"
                :options="sign_list"></b-form-select>
            </div>
          </template>
        </b-th>
      </b-tr>
    </b-table-simple>
    <b-table :busy="busy" fixed :current-page="currentPage" :per-page="perPage" :items="data_list"
      :fields="data_list_field" bordered striped :sticky-header="maxHeight2" :filter="filterFun"
      :filter-function="tableFilter" head-variant="dark">
      <!-- <template #head(mitumori_sel)="data">
        <span class="text-info">{{ data.label.toUpperCase() }}</span>
      </template> -->
      <!-- Loading -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <!-- 全て -->
      <template #cell()="data">
        <span v-if="data.value == '編集'">
          <span style="color: #20a320;">{{ data.value }}</span>
        </span>
        <span v-else-if="data.value == '表示'">
          <span style="color: #5f9ea0;">{{ data.value }}</span>
        </span>
        <span v-else-if="data.value == '非表示'">
          <span style="color: #ff0000;">{{ data.value }}</span>
        </span>
        <span v-else style="">
          {{ data.value }}
        </span>
      </template>
      <!-- index -->
      <template #cell(index)="data">
        {{ data.index + 1 + ((currentPage - 1) * perPage) }}
      </template>
      <!-- m_code -->
      <template #cell(m_code)="data">
        <span v-if="data.item.cancel == true">
          <s>{{ data.item.m_code }}</s>
        </span>
        <span v-else>
          {{ data.item.m_code }}
        </span>
      </template>
      <!-- m_code -->
      <template #cell(m_code_ex)="data">
        <span v-if="data.item.cancel == true">
          <s>{{ data.item.m_code_ex }}</s>
        </span>
        <span v-else>
          {{ data.item.m_code_ex }}
        </span>
      </template>
      <!-- edit -->
      <template #cell(edit)="data">
        <b-button v-if="data.field.select == true" @click="onSelect(data.item.id)" variant="primary" class="icon_btn">
          <b-icon icon="check-circle" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="data.field.edit == true" @click="onEdit(data.item.id)" variant="info" class="icon_btn"
          v-bind:disabled="edit_disabled || data.item.edit_disabled">
          <b-icon icon="pencil" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="data.field.detail == true" @click="onDetail(data.item.id)" variant="info" class="icon_btn"
          >
          <b-icon icon="info-circle" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="data.field.delete == true" @click="onDelete(data.item.id)" variant="danger" class="icon_btn"
          v-bind:disabled="edit_disabled || data.item.delete_disabled">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <!-- 見積選択 -->
      <template #cell(mitumori_sel)="data">
          <span v-if="data.item.mitumori_sel">
            <b-button @click="onMitumoriSel(data.index, data.item.id, data.item.m_code, false)" variant="info" class="icon_btn">
              <b-icon icon="check-circle" aria-hidden="true"></b-icon>
            </b-button>
          </span>
          <span v-else>
            <b-button @click="onMitumoriSel(data.index, data.item.id, data.item.m_code, true)" variant="outline-info2" class="icon_btn">
              <b-icon icon="check-circle" aria-hidden="true"></b-icon>
            </b-button>
          </span>
      </template>
      <!-- 入金選択 -->
      <template #cell(nyukin_sel)="data">
        <b-form-checkbox v-model="data.item.nyukin_sel" class="icon_btn" button button-variant="outline-info2"
          @change="onNyukinSel(data.item.id, data.item.m_code, $event)">
          <b-icon icon="check-circle" style="margin: -3px;"></b-icon>
        </b-form-checkbox>
      </template>
      <!-- 受注状態 -->
      <template #cell(jyutyu_status)="data">
        <span v-if="data.value[0] == -1" style="color: #808080">
          -
        </span>
        <span v-if="data.value[0] == 0" style="color: #ff0000">
          未
        </span>
        <span v-if="data.value[0] == 1" @click="jyutyuShow(data.value[1])" style="color: #5f9ea0; cursor: pointer;">
          △
        </span>
        <span v-if="data.value[0] == 2" @click="jyutyuShow(data.value[1])" style="color: #20a320; cursor: pointer;">
          済
        </span>
      </template>
      <!-- 納品状態 -->
      <template #cell(nouhin_status)="data">
        <span v-if="data.value[0] == -1" style="color: #808080">
          -
        </span>
        <span v-if="data.value[0] == 0" @click="nouhinShow(data.value[1])" style="color: #ff0000; cursor: pointer;">
          未
        </span>
        <span v-if="data.value[0] == 1" @click="nouhinShow(data.value[1])" style="color: #daa520; cursor: pointer;">
          可
        </span>
        <span v-if="data.value[0] == 2" @click="nouhinShow(data.value[1])" style="color: #20a320; cursor: pointer;">
          済
        </span>
      </template>
      <!-- 請求状態 -->
      <template #cell(seikyu_status)="data">
        <span v-if="data.value[0] == -1" style="color: #808080">
          -
        </span>
        <span v-if="data.value[0] == 0" @click="seikyuShow(data.value[1])" style="color: #ff0000; cursor: pointer;">
          未
        </span>
        <span v-if="data.value[0] == 1" @click="seikyuShow(data.value[1])" style="color: #5f9ea0; cursor: pointer;">
          可
        </span>
        <span v-if="data.value[0] == 2" @click="seikyuShow(data.value[1])" style="color: #20a320; cursor: pointer;">
          済
        </span>
      </template>
      <!-- 入金状態 -->
      <template #cell(nyukin_status)="data">
        <span v-if="data.value[0] == -1" style="color: #808080">
          -
        </span>
        <span v-if="data.value[0] == 0" @click="nyukinShow(data.value[1])" style="color: #ff0000; cursor: pointer;">
          未
        </span>
        <!-- <span v-if="data.value[0] == 1" @click="nyukinShow(data.value[1])" style="color: #5f9ea0; cursor: pointer;">
          可
        </span> -->
        <span v-if="data.value[0] == 1" @click="nyukinShow(data.value[1])" style="color: #20a320; cursor: pointer;">
          済
        </span>
      </template>
    </b-table>
    <table v-if="nav_flg == true" style="margin-top: -25px;">
      <tr>
        <td style="">
          {{ data_list.length }}件{{ }}
        </td>
        <td style="">
          <nav>
            <b-pagination v-model="currentPage" :total-rows="data_list.length" :per-page="perPage" first-text="First"
              prev-text="Prev" next-text="Next" last-text="Last" style="margin-top: 10px" />
          </nav>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>



export default {
  props: ['maxHeight', 'filter_list', 'filter_val', 'filter_val_ex', 'filter_val_sign', 'data_list', 'data_list_field', 'filter_enable',
    'nav_flg', 'busy', 'edit_disabled'],
  // axiosInstance,
  mounted() {
    if (this.nav_flg) {
      this.perPage = 100;
    }
    if (this.maxHeight.substr(0, this.maxHeight.indexOf('p')) > 200) {
      this.maxHeight2 = this.maxHeight
    }

  },
  computed: {
    filterFun: function () {
      return this.filter_val
    }
  },
  data: function () {
    return {
      currentPage: 1,
      perPage: 0,
      maxHeight2: '200px',
      sign_list: [{ value: ">=", text: ">=" }, { value: ">", text: ">" },
      { value: "=", text: "=" }, { value: "<=", text: "<=" }, { value: "<", text: "<" }]
    }
  },
  methods: {
    onMitumoriSel: function (index, id, m_code_ex, event) {
      this.$emit('on_mitumori_sel', index, id, m_code_ex, event);
    },
    onNyukinSel: function (id, m_code_ex, event) {
      // console.log(document.getElementById('nyukin_sel' + id))
      this.$emit('on_nyukin_sel', id, m_code_ex, event);
    },
    onHattyuChk: function (id, event) {
      this.$emit('on_hattyu_chk', id, event);
    },
    onKensyuChk: function (id, event) {
      this.$emit('on_kensyu_chk', id, event);
    },
    onSelect: function (id) {
      this.$emit('on_select', id);
    },
    onDetail: function (id) {
      this.$emit('on_detail', id);
    },
    onEdit: function (id) {
      this.$emit('on_edit', id);
    },
    onDelete: function (id) {
      this.$emit('on_delete', id);
    },
    onFilterEx: function (id) {
      this.$emit('on_filter_ex', id);
    },
    // 
    jyutyuShow: function (mitumori_id) {
      this.$emit('jyutyu_show', mitumori_id);
    },
    nouhinShow: function (mitumori_id) {
      this.$emit('nouhin_show', mitumori_id);
    },
    seikyuShow: function (mitumori_id) {
      this.$emit('seikyu_show', mitumori_id);
    },
    nyukinShow: function (mitumori_id) {
      this.$emit('nyukin_show', mitumori_id);
    },
    showAlert: function () {
      alert('作成されていません');
    },
    // Table Filter
    tableFilter: function (row) {
      var flg_list = new Array(this.data_list_field.length)
      var filter = this.filter_val
      this.data_list_field.forEach(function (elem, index) {
        if (elem.filter === false) {
          flg_list[index] = true
        } else if (elem.filter === 'input') {
          flg_list[index] = filter[index] ? String(row[elem.key]).toLowerCase().includes(filter[index].toLowerCase()) : true
        } else if (elem.filter === 'select') {
          flg_list[index] = filter[index] ? String(row[elem.key]).includes(filter[index]) : true
        } else if (elem.filter === 'select_sts') {
          flg_list[index] = filter[index] ? String(row[elem.key][0]) == (filter[index]) : true
        } else {
          flg_list[index] = true
        }
      });
      return flg_list.indexOf(false) == -1 ? true : false
    },
  }
}
</script>
  
<style scoped></style>