<template>
  <div>
    <b-modal :id="modal_id" title="マスタ > その他" size="lg" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered>
      <b-form-select v-model="on_submit" style="width: 200px" @change="selSubmit">
        <option disabled value="-1">マスタを選択</option>
        <option v-for="option in sel_on_submit" v-bind:value="option.value" :key="option.id">
          {{ option.text }}
        </option>
      </b-form-select>
      <div style="margin-top: 10px;">
        <div v-if="on_submit==0">
          <MasterItemType ref="child_master_item_type" />
        </div>
        <div v-if="on_submit==1">
          <MasterItemTax ref="child_master_item_tax" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

// vue-good-table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      on_submit: 0,
      sel_on_submit: [{ value: 0, text: "商品種別" }, { value: 1, text: "税率" }],

      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 250 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    selSubmit: function (id) {
    },
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },

  }
}
</script>

<style scoped></style>