<template>
  <div id="login">
    <!-- 受注 -->
    <br />
    <form @submit.prevent="updateJyutyu">
      <div class="form-title">ログイン</div>
      <div>ID</div>
      <div class="mail">
        <input v-model="login_inf.login_id" type="text" style="width: 330px;" />
      </div>
      <div>パスワード</div>
      <div class="pass">
        <input v-model="login_inf.password" type="password" style="width: 330px;" />
      </div>
      <div class="check">
        <input type="checkbox" id="pass_save" v-model="login_inf.pass_save" />
        <label for="pass_save">IDとパスワードを保存</label>
      </div>
      <div class="submit">
        <b-button @click="login()" variant="primary" style="width: 120px !important">
          ログイン
        </b-button>
      </div>
    </form>
  </div>

</template>

<script>
import Vue from 'vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

const jsonpAdapter = require('axios-jsonp');
export default {
  components: {
  },
  data: function () {
    return {
      login_inf: [],

      errors: [],
    }
  },
  mounted() {
    this.getLogin()
  },
  methods: {

    // ログイン 取得
    getLogin: function () {
      axios
        .get('/login/get_login.json', {}, {withCredentials: true})
        .then(response => {
          this.login_inf = response.data;
        })
    },
    // ログイン
    login: function () {
      axios
        .post(`/login/login`, { login: this.login_inf }, {withCredentials: true})
        .then(response => {
          if (response.data) {
            this.$router.push({ name: 'main' })
          } else {
            alert("無効なID／パスワードの組み合わせです")
          }
        })
    },
  }
}
</script>

<style scoped>
#login{
    width: 380px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #555;
}

#login .form-title{
    text-align: center;
}

#login .mail,.pass{
    margin-bottom: 20px;
}

#login .input {
    width: 330px;
    padding: 4px;
    font-size: 14px;
}

#login .submit{
    text-align: right;
}

/* font */
#login div{
    color: #077685;
    font-weight: bold;
}

#login  .form-title{
    font-family: Arial;
    font-size: 25px;
    color: #4eb4c2;
}

/* skin */
#login{
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 1px 10px #488a9e;
    -moz-box-shadow: 0px 1px 10px #488a9e;
    box-shadow: 0px 1px 10px #488a9e;
    border: solid #4eb4c2 1px;
    background: #fafafa;
}

#login .form-title{
    padding-bottom: 6px;
    border-bottom: 2px solid #4eb4c2;
    margin-bottom: 20px;
}

#login .submit input{
    font-family: Arial;
    color: #ffffff;
    font-size: 16px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    text-decoration: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 8px 6px #e3e3e3;
    -moz-box-shadow: 0px 8px 6px #e3e3e3;
    box-shadow: 0px 8px 6px #e3e3e3;
    border: solid #f5fdff 4px;
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#61c7e0), to(#418da8));
    background: -moz-linear-gradient(top, #61c7e0, #418da8);
}
#login .submit input:hover{
    background: #37a4bf;
}


</style>