<template>
  <div>
    <b-modal :id="modal_id" title="入金" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 一覧 -->
      <div>
        <div class="form-inline">
          <label class="block_label2">顧客</label>
          <b-input v-model="client_company" type="text" style="width: 300px" disabled />
        </div>
        <div class="form-inline">
          <label class="block_label2">品題</label>
          <b-input v-model="mitumori.title" type="text" style="width: 200px" disabled />
        </div>
        <div class="form-inline">
          <label class="block_label2">請求日</label>
          <b-input v-model="nyukin.seikyu_on_fmt" type="text" style="width: 150px" disabled />
        </div>
        <div class="form-inline">
          <label class="block_label2">請求額</label>
          <b-input v-model="nyukin.price_fmt" type="text" style="width: 100px; text-align: right;" disabled />
        </div>
        <div class="form-inline">
          <label class="block_label2">入金日</label>
          <b-input v-model="nyukin.nyukin_on_fmt" type="text" style="width: 150px" disabled />
        </div>
        <div class="form-inline">
          <label class="block_label2">入金</label>
          <b-input v-model="nyukin.nyukin_price_fmt" type="text" style="width: 100px; text-align: right;" disabled />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "index", label: "", thStyle: { width: "50px" }, tdClass: 'text-center', sortable: false, filter: false },
        { key: "edit", label: "", thStyle: { width: "100px" }, tdClass: 'text-center', sortable: false, filter: false, edit: true, delete: true },
        { key: "m_code_ex", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "title", label: "品題", thStyle: { width: "180px" }, sortable: true, filter: 'input' },
        { key: "client_company", label: "顧客", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "price", label: "金額", thStyle: { width: "150px" }, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "schedule_on", label: "入金予定日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "nyukin_on", label: "入金日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "status", label: "状態", thStyle: {}, sortable: true, filter: 'select' },
      ],
      // モーダル
      client_company: "",
      mitumori: [],
      nyukin_id: "",
      nyukin: [],
      sel_status: [],
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 200 + "px"
    }
  },
  mounted() {
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 見積IDから入金ID取得
    getMitumori2Nyukin: function (mitumori_id) {
      axios
        .get(`/mitumori/get_mitumori_2_nyukin/${mitumori_id}`)
        .then(response => {
          this.nyukin_id = response.data.nyukin_id;
          axios
            .get(`/nyukin/get_nyukin/${this.nyukin_id}`)
            .then(response2 => {
              this.client_company = response.data.client_company;
              this.mitumori = response.data.mitumori;
              this.nyukin = response2.data.nyukin_h;
            })
        })
    },

  }
}
</script>

<style scoped></style>