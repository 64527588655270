<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal :id="modal_id" title="売掛" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 一覧 -->
      <div class="main_modal_div" style="min-height: 500px;">
        <div style="margin-bottom: 5px;" class="form-inline">
          <b-form-select v-model="on_type" style="width: 100px" @change="onDateChange">
            <option v-for="option in sel_on_type" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          <v-date-picker v-model="date1" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;-&nbsp;
          <v-date-picker v-model="date2" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
        </div>
        <div style="width: 1200px; ">
          <b-table-filter-pager :busy="busy" :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :filter_val_ex="filter_val_ex" :data_list_field="data_list_field" :data_list="data_list"
            :data_count="data_count" :currentPage="current_page" @on_sort="onSort" @on_page_change='onPageChange'
            @on_edit='modalShow' @on_filter_ex='onFilterEx' @on_nyukin_chk='onNyukinChk' 
            :filter_enable="true"
            :nav_flg="true" :add_btn="false" :edit_disabled="pms_nyukin < 2" />
        </div>
        <div class="form-inline" style="width: 1200px; margin-top: 0px; margin-left: -10px;">
          <label style="margin-left: 15px; float: right;">入金合計</label>
          <b-input v-model="sum_nyukin_price" type="text" style="width: 140px; text-align: right;" disabled />
          <label style="margin-left: 10px">手数料合計</label>
          <b-input v-model="sum_commission" type="text" style="width: 110px; text-align: right;" disabled />
          <label style="margin-left: 10px">差額合計</label>
          <b-input v-model="sum_difference" type="text" style="width: 110px; text-align: right;" disabled />
          <label style="margin-left: 10px">請求額合計</label>
          <b-input v-model="sum_price" type="text" style="width: 140px; text-align: right;" disabled />
          <label style="margin-left: 10px">未処理合計</label>
          <b-input v-model="sum_un_price" type="text" style="width: 140px; text-align: right;" disabled />
        </div>
      </div>
      <!-- モーダル -->
      <b-modal id="modal-nyukin" title="売掛 > 編集" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label class="block_label">伝票番号</label>
            <b-input v-model="nyukin.m_code_ex" type="text" style="width: 150px" disabled />
          </div>
          <div class="form-inline">
            <label class="block_label">品題</label>
            <b-input v-model="nyukin.title" type="text" style="width: 200px" disabled />
          </div>
          <div class="form-inline">
            <label class="block_label">顧客</label>
            <b-input v-model="nyukin.client_company" type="text" style="width: 300px" disabled />
          </div>
          <div class="form-inline">
            <label class="block_label">入金日</label>
            <v-date-picker v-model="nyukin.nyukin_on" class="date_picker" @input="onNyukinOnChange" is-required>
              <template v-slot="{ inputValue, inputEvents }">
                <b-input :value="inputValue" v-on="inputEvents" readonly />
              </template>
            </v-date-picker>
          </div>
          <div class="form-inline">
            <label class="block_label">請求額</label>
            <b-input v-model="nyukin.price" type="number" style="width: 150px; text-align: right;" disabled />
          </div>
          <div class="form-inline">
            <label class="block_label">入金</label>
            <b-input v-model="nyukin.nyukin_price" type="number" @change="onNyukinChange('nyukin_price')"
              style="width: 150px; text-align: right;" />
          </div>
          <div class="form-inline">
            <label class="block_label">手数料</label>
            <b-input v-model="nyukin.commission" type="number" @change="onNyukinChange('commission')"
              style="width: 150px; text-align: right;" />
          </div>
          <div class="form-inline">
            <label class="block_label">差額</label>
            <b-input v-model="nyukin.difference" type="number" style="width: 150px; text-align: right;" disabled />
          </div>
          <div class="form-inline">
            <label class="block_label">摘要</label>
            <b-input v-model="nyukin.note" type="text" style="width: 300px; " />
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-nyukin')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="primary" @click="updateNyukin">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      on_type: 1,
      sel_on_type: [{ value: 0, text: "入金日" }, { value: 1, text: "請求日" }],
      date1: '',
      date2: '',
      // table
      busy: false,
      isLoading: false,
      current_page: 1,
      data_count: 0,
      sort_by: '',
      sort_desc: true,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "nyukin_on", label: "入金日", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "nyukin_price_check", label: "入金", thStyle: { width: "130px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "commission", label: "手数料", thStyle: { width: "90px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "difference", label: "差額", thStyle: { width: "90px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "price", label: "請求額", thStyle: { width: "90px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "seikyu_on", label: "請求日", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "m_code_ex", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "title", label: "品題", thStyle: { width: "100px" }, sortable: true, filter: 'select_ex2' },
        { key: "client_company", label: "顧客", thStyle: { width: "150px" }, sortable: true, filter: 'select_ex2' },
        { key: "user_name", label: "担当者", thStyle: { width: "" }, sortable: true, filter: 'select_ex2' },
        // { key: "note", label: "摘要", thStyle: {}, sortable: true, filter: 'input_ex' },
      ],
      //
      sum_price: 0,
      sum_un_price: 0,
      sum_nyukin_price: 0,
      sum_commission: 0,
      sum_difference: 0,
      // モーダル
      nyukin_id: "",
      nyukin: [],
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 340 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getNyukinList();
    },
    onDateChange: function () {
      this.current_page = 1;
      this.getNyukinList();
    },
    onFilterEx: function () {
      this.current_page = 1;
      this.getNyukinList();
    },
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 見積IDから入金ID取得
    getMitumori2Nyukin: function (mitumori_id) {
      axios
        .get(`/mitumori/get_mitumori_2_nyukin/${mitumori_id}`)
        .then(response => {
          // this.getNyukinList();
          this.filter_val[2] = response.data.m_code;
          this.nyukin_id = response.data.nyukin_id;
          axios
            .get(`/nyukin/get_nyukin/${this.nyukin_id}`)
            .then(response => {
              this.nyukin = response.data.nyukin_h;
            })
        })
    },
    // 入金一覧 取得
    getNyukinList: function () {
      this.isLoading = true;
      axios
        .get('/nyukin/get_nyukin_list', {
          params: {
            on_type: this.on_type, date1: this.date1, date2: this.date2,
            filter_val_ex: this.filter_val_ex,
            nyukin_on_ex: this.filter_val_ex[1],
            nyukin_price_ex: this.filter_val_ex[2],
            commission_ex: this.filter_val_ex[3],
            difference_ex: this.filter_val_ex[4],
            price_ex: this.filter_val_ex[5],
            seikyu_on_ex: this.filter_val_ex[6],
            m_code_ex: this.filter_val_ex[7],
            title_ex: this.filter_val_ex[8],
            client_company_ex: this.filter_val_ex[9],
            user_ex: this.filter_val_ex[10],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list = response.data.nyukin_list;
          this.data_count = response.data.nyukin_count;
          this.date1 = response.data.date1;
          this.date2 = response.data.date2;
          this.filter_list[8] = response.data.title_filter_list;
          this.filter_list[9] = response.data.client_company_filter_list;
          this.filter_list[10] = response.data.user_filter_list;
          this.sum_price = response.data.sum_price;
          this.sum_un_price = response.data.sum_un_price;
          this.sum_nyukin_price = response.data.sum_nyukin_price;
          this.sum_commission = response.data.sum_commission;
          this.sum_difference = response.data.sum_difference;
          this.isLoading = false;

        })
    },
    // 受注 削除
    delNyukin: function (nyukin_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/nyukin/del_nyukin/${nyukin_id}`)
          .then(response => {
            (this.data_list = response.data[0]);
          })
      }
    },
    // 入金チェック
    onNyukinChk: function (nyukin_id, flg) {
      var msg = ["入金済みにしますか？", "入金済みにしました"]
      if (flg == false) {
        msg = ["入金済みを取り消しますか？", "入金済みを取り消しました"]
      }
      if (confirm(msg[0])) {
        axios
          .delete("/nyukin/check_nyukin", {
            params: { nyukin_id: nyukin_id, flg: flg }
          })
          .then(response => {
            alert(msg[1])
            this.getNyukinList();
          })
      }
    },
    // モーダル(新規、編集) 表示
    modalShow: function (nyukin_id) {
      axios
        .get(`/nyukin/get_nyukin/${nyukin_id}`)
        .then(response => {
          this.nyukin = response.data.nyukin_h;
          this.$root.$emit('bv::show::modal', 'modal-nyukin')
        })
    },
    // 入金 編集
    updateNyukin: function () {
      if (this.nyukin.nyukin_on == null) {
        alert("入金日を選択してください");
      } else {
        axios
          .patch(`/nyukin/update_nyukin`, { nyukin: this.nyukin })
          .then(response => {
            (this.client_company = response.data);
            this.getNyukinList();
            this.$root.$emit('bv::hide::modal', 'modal-nyukin')
          })
      }
    },
    // 入金日入力
    onNyukinOnChange: function () {
      if (this.nyukin.nyukin_price == 0) {
        this.nyukin.nyukin_price = this.nyukin.price;
        this.onNyukinChange('nyukin_price');
      }
    },
    // 入金、手数料入力
    onNyukinChange: function (node) {
      var chg_node = this.nyukin.nyukin_price
      switch (node) {
        case 'nyukin_price':
          chg_node = this.nyukin.nyukin_price;
          break;
        case 'commission':
          chg_node = this.nyukin.commission;
          break;
      }
      if (chg_node === "") {
        alert("数値を入力してください");
        switch (node) {
          case 'nyukin_price':
            this.nyukin.nyukin_price = 0;
            break;
          case 'commission':
            this.nyukin.commission = 0;
            break;
        }
      } else {
        this.nyukin.difference = (Number(this.nyukin.nyukin_price) + Number(this.nyukin.commission)) - Number(this.nyukin.price)
      }
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getNyukinList();
    }

  }
}
</script>

<style scoped></style>