<template>
  <div>
    <b-modal :id="modal_id" title="マスタ > 自社" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 自社 -->
      <form class="main_modal_div" @submit.prevent="updateCompany">
        <div v-if="errors.length != 0">
          <ul v-for="e in errors" :key="e">
            <li>
              <font color="red">{{ e }}</font>
            </li>
          </ul>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>会社名</label>
          <b-input v-model="company.company_name" type="text" style="width: 200px" />
          &nbsp;&nbsp;
          <label>代表者名</label>
          <b-input v-model="company.representative" type="text" />
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>郵便番号</label>
          <b-input v-model="company.zip_code" type="text" placeholder="123-4567" style="width: 100px" />
          <b-button @click="searchAddress" style="width: 110px !important">
            <b-icon icon="search"></b-icon>住所検索
          </b-button>
          &nbsp;&nbsp;
          <label>住所</label>
          <b-input v-model="company.address" type="text" style="width: 300px" />
          &nbsp;&nbsp;
          <label>TEL</label>
          <b-input v-model="company.tel" type="text" style="width: 200px" />
          &nbsp;&nbsp;
          <label>FAX</label>
          <b-input v-model="company.fax" type="text" style="width: 200px" />
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>インボイス番号</label>
          <b-input v-model="company.invoice_num" type="text" style="width: 170px" />
          &nbsp;&nbsp;
          <label>取引先銀行</label>
          <b-input v-model="company.bank" type="text" style="width: 670px" />
          <b-button type="submit" variant="primary" class="" style="margin-left: 20px; "
            :disabled="pms_master_company < 2">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
        <div style="margin: 10px 0px">
        </div>
      </form>
      <hr />
      <!-- 担当者 -->
      <div>担当者</div>
      <div style="overflow: auto;">
        <div style="width: 1200px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_edit='modalUserShow' @on_delete='delUser'
            :filter_enable="true" :edit_disabled="pms_master_company < 2" />
        </div>
      </div>
      <div style="margin: 5px 0px 0px 0px">
        {{ data_list.length }}件&nbsp;&nbsp;
        <b-button @click="modalUserShow(-1)" ref="btnShow" variant="info" class="icon_btn_w"
          :disabled="pms_master_company < 2">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <!-- モーダル 担当者詳細 -->
      <b-modal id="modal-user" :title="title" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>ログインID</label>
            <b-input v-model="user.login_id" type="text" style="" />
          </div>
          <div class="form-inline">
            <label>姓</label>
            <b-input v-model="user.name1" type="text" style="width: 120px" />
            &nbsp;&nbsp;
            <label>名</label>
            <b-input v-model="user.name2" type="text" style="width: 120px" />
          </div>
          <div class="form-inline">
            <label>セイ</label>
            <b-input v-model="user.name1_kana" type="text" style="width: 120px" />
            &nbsp;&nbsp;
            <label>メイ</label>
            <b-input v-model="user.name2_kana" type="text" style="width: 120px;" />
          </div>
          <div class="form-inline">
            <label>販売</label>
            <b-form-select v-model="user.pms_hanbai" style="width: 90px">
              <option v-for="option in sel_pms_list1" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            &nbsp;&nbsp;
            <label>入金</label>
            <b-form-select v-model="user.pms_nyukin" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            &nbsp;&nbsp;
            <label>在庫</label>
            <b-form-select v-model="user.pms_zaiko" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>仕入</label>
            <b-form-select v-model="user.pms_hattyu" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            &nbsp;&nbsp;
            <label>自社</label>
            <b-form-select v-model="user.pms_master_company" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            &nbsp;&nbsp;
            <label>発注先</label>
            <b-form-select v-model="user.pms_master_hattyu_company" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>顧客</label>
            <b-form-select v-model="user.pms_master_client_company" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            &nbsp;&nbsp;
            <label>商品</label>
            <b-form-select v-model="user.pms_master_item" style="width: 90px">
              <option v-for="option in sel_pms_list2" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div>
          <b-button @click="$bvModal.hide('modal-user')" style="margin-left: 240px;">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="primary" @click="updateUser">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
        <hr>
        <div v-if="user.id !== null" class="form-inline">
          <label>パスワード</label>
          <b-input v-model="user.password" type="password" style="width: 130px" autocomplete="new-password" />
          <label>&nbsp;&nbsp;再入力</label>
          <b-input v-model="user.password_confirmation" type="password" style="width: 130px" />
        </div>
        <div v-if="user.id !== null" class="form-inline" style="margin-left: 250px;">
          <b-button variant="primary" @click="changePassword" style="width: 200px !important;">
            <b-icon icon="save"></b-icon>&nbsp;パスワード変更
          </b-button>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // header
      company: [],
      authority: 0,
      // table
      sel_pms_list1: [{ text: "非表示", value: 0 }, { text: "編集", value: 2 }],
      sel_pms_list2: [{ text: "非表示", value: 0 }, { text: "表示", value: 1 }, { text: "編集", value: 2 }],
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "login_id", label: "ログインID", thStyle: { width: "140px" }, sortable: true, filter: 'input' },
        { key: "name1", label: "姓", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "name2", label: "名", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "name1_kana", label: "セイ", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "name2_kana", label: "メイ", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "pms_hanbai", label: "販売", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_nyukin", label: "入金", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_zaiko", label: "在庫", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_hattyu", label: "仕入", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_master_company", label: "自社", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_master_hattyu_company", label: "発注先", thStyle: { width: "90px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_master_client_company", label: "顧客", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'input' },
        { key: "pms_master_item", label: "商品", thStyle: { width: "" }, tdClass: 'text-center', sortable: true, filter: 'input' }
      ],
      // modal
      user: [],
      title: '',

      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      dd: true,
      maxHeight: document.documentElement.scrollHeight - 440 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 自社情報 取得
    getCompany: function () {
      axios
        .get('/master_company/get_company.json')
        .then(response => {
          (this.company = response.data.company);
          this.authority = response.data.authority
          this.getUserList();
        })
    },
    // 自社情報 更新
    updateCompany: function () {
      axios
        .patch(`/master_company/update_company`, { company: this.company })
        .then(response => {
          (this.company = response.data.company);
          alert("保存しました。");
        })
    },
    // =========================================================
    // 担当者 取得
    getUserList: function () {
      axios
        .get(`/master_company/get_user_list/${this.company.id}`)
        .then(response => { (this.data_list = response.data.user_list); })
    },
    // 担当者モーダル 表示
    modalUserShow: function (user_id) {
      if (user_id == -1) {
        this.title = "担当者 > 追加"
      } else {
        this.title = "担当者 > 編集"
      }
      this.sel_user_id = user_id
      axios
        .get(`/master_company/get_user/${user_id}`)
        .then(response => {
          this.user = response.data.user_h;
          console.log(response.data.user_h)
          this.$root.$emit('bv::show::modal', 'modal-user')
        })
    },
    // パスワード変更
    changePassword: function () {
      axios
        .patch(`/master_company/change_password`, { user: this.user })
        .then(response => {
          if (response.data.flg) {
            alert('変更しました');
          } else {
            alert(response.data.msg);
          }
        })
    },
    // 担当者 更新
    updateUser: function () {
      axios
        .patch(`/master_company/update_user`, { company: this.company, user: this.user })
        .then(response => {
          this.getUserList();
          this.$root.$emit('bv::hide::modal', 'modal-user')
        })
    },
    // 担当者 削除
    delUser: function (user_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/master_company/del_user/${user_id}`)
          .then(response => {
            (this.data_list = response.data.user_list);
          })
      }
    },
    // 郵便番号検索
    searchAddress: function () {
      const zipCode = this.company.zip_code;
      axios
        .get(`https://api.zipaddress.net/?zipcode=${zipCode}`, { adapter: jsonpAdapter })
        .then(rs => {
          const response = rs.data;
          if (response.code == '400') {
            alert('郵便番号は数値7桁で入力してください');
          } else {
            this.company.address = response.pref + response.city + response.town
          }
        })
    },
  }
}
</script>

<style scoped></style>