<template>
  <div>
    <b-modal :id="modal_id" title="受注" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 一覧 -->
      <div :style="'width: ' + maxWidth + '; overflow: scroll'">
        <div style="width: 1070px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_detail='toJyutyuDetail' @on_delete='delJyutyu'
            :filter_enable="true" />
        </div>
      </div>
    </b-modal>
    <!-- 受注 詳細 モーダル -->
    <JyutyuDetail ref="child_jyutyu_detail" @close_modal='closeDetailModal' :modal_id="'jyutyu2jyutyu_detail'" />
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "index", label: "", thStyle: { width: "50px" }, tdClass: 'text-center', sortable: false, filter: false },
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, detail: true },
        { key: "m_code", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "title", label: "品題", thStyle: { width: "180px" }, sortable: true, filter: 'input' },
        { key: "mitumori_on", label: "見積日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "jyutyu_on", label: "受注日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "client_company_name", label: "顧客", thStyle: { width: "140px" }, sortable: true, filter: 'input' },
        { key: "client_person_name", label: "顧客担当者", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "user_name", label: "担当者", thStyle: {}, sortable: true, filter: 'select' },
        // { key: "status", label: "状態", thStyle: {}, sortable: true, filter: 'select' },
      ],
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 200 + "px"
    }
  },
  mounted() {
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    closeDetailModal: function () {
      this.getJyutyuList();
    },  
    // 受注一覧 取得
    getJyutyuList: function () {
      axios
        .get('/jyutyu/get_jyutyu_list')
        .then(response => {
          this.data_list = response.data.jyutyu_list;
          this.filter_list[8] = response.data.user_filter_list;
          this.filter_list[9] = response.data.status_filter_list;
          this.filter_val[8] = response.data.user_name;
        })
    },
    // 受注 詳細
    toJyutyuDetail: function (jyutyu_id) {
      this.$refs.child_jyutyu_detail.setJyutyuId(jyutyu_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'jyutyu2jyutyu_detail');
      }, 100);
    },
    // 受注 削除
    delJyutyu: function (jyutyu_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/jyutyu/del_jyutyu/${jyutyu_id}`)
          .then(response => {
            if (response.data.flg == false) {
              alert('納品済みは削除できません')
            } else {
              this.data_list = response.data.jyutyu_list;
            }
          })
      }
    }
  }
}
</script>

<style scoped></style>