<template>
  <div>
    <!-- ================================================================== -->
    <!-- Header -->
    <!-- ================================================================== -->
    <Header ref="header"></Header>
  </div>
</template>

<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {

    }
  },
  mounted() {
    this.checkLogin()
  },
  methods: {

  }
}
</script>

<style scoped></style>