<template>
  <div ref="title" style="">
    <!-- <Header /> -->
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router';
import Header from 'components/header.vue';
import Main from 'mitumori/main.vue';
import Top from 'top/index.vue';
import login from 'login/index.vue'

import master_company from 'master_company/index.vue'
import master_client_company from 'master_client_company/index.vue'
import master_client_company_detail from 'master_client_company/detail.vue'
import master_hattyu_company from 'master_hattyu_company/index.vue'
import master_hattyu_company_detail from 'master_hattyu_company/detail.vue'
import master_item from 'master_item/index.vue'
import mitumori_detail from 'mitumori/detail.vue'
import jyutyu from 'jyutyu/index.vue'
import jyutyu_detail from 'jyutyu/detail.vue'
import nouhin from 'nouhin/index.vue'
import nouhin_detail from 'nouhin/detail.vue'
import zaiko from 'zaiko/index.vue'
import hattyu from 'hattyu/index.vue'
import nyukin from 'nyukin/index.vue'

const router = new VueRouter({
  routes: [
    {
      path: '/', component: Main
    },
    {
      path: '/top', component: Top, name: 'top'
    },
    {
      path: '/main', component: Main, name: 'main'
    }, 
       // ログイン
    {
      path: '/login', component: login, name: 'login'
    },
  ]
})

Vue.use(VueRouter)

export default {
  mounted : function(){
    document.title = '販売管理'
  },    
  components: {
    Header
  },
  data: function () {
    return {

    }
  },
  router
}
</script>

<style scoped>
#router-view {
  margin: 10px 20px;
}
</style>