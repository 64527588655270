<template>
  <div>
    <b-modal :id="modal_id" :title="top_title" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 自社 -->
      <form @submit.prevent="updateClientCompany">
        <div v-if="errors.length != 0">
          <ul v-for="e in errors" :key="e">
            <li>
              <font color="red">{{ e }}</font>
            </li>
          </ul>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>会社コード</label>
          <b-input v-model="client_company.company_code" type="text" />
          <label>会社名</label>
          <b-input v-model="client_company.company_name" type="text" style="width: 300px" />
          &nbsp;&nbsp;
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>郵便番号</label>
          <b-input v-model="client_company.zip_code" type="text" placeholder="123-4567" style="width: 100px" />
          <b-button @click="searchAddress" style="width: 110px !important">
            <b-icon icon="search"></b-icon>住所検索
          </b-button>
          &nbsp;&nbsp;
          <label>住所1</label>
          <b-input v-model="client_company.address1" type="text" style="width: 300px" />
          &nbsp;&nbsp;
          <label>住所2</label>
          <b-input v-model="client_company.address2" type="text" style="width: 200px" />
          &nbsp;&nbsp;
          <label>住所3</label>
          <b-input v-model="client_company.address3" type="text" style="width: 150px" />
        </div>
        <div>
          <label>担当者</label>
          <b-form-select v-model="client_company.user_id" style="width: 200px">
            <option v-for="option in sel_user_list" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          <b-button type="submit" variant="primary" style="margin-left: 660px" :disabled="pms_master_client_company < 2">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
          <b-button @click="delClientCompany(client_company_id)" variant="danger" :disabled="pms_master_client_company < 2">
            <b-icon icon="save"></b-icon>&nbsp;削除
          </b-button>
        </div>
      </form>
      <hr />
      <!-- 担当者 -->
      <div>顧客担当者</div>
      <div>
        <div style="width: 800px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_edit='modalShow' @on_delete='delClientPerson'
            :filter_enable="false" :nav_flg="false" :edit_disabled="pms_master_client_company < 2" />
        </div>
      </div>
      <div style="margin: -10px 0px 0px 0px">
        {{ data_list.length }}件&nbsp;&nbsp;
        <b-button @click="modalShow(-1)" ref="btnShow" variant="info" class="icon_btn_w" :disabled="pms_master_client_company < 2">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <!-- モーダル 担当者詳細 -->
      <b-modal id="modal-client-person" :title="title" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>担当者名</label>
            <b-input v-model="client_person.person_name" type="text" />
          </div>
          <div class="form-inline">
            <label>TEL</label>
            <b-input v-model="client_person.tel" type="text" style="" />
          </div>
          <div class="form-inline">
            <label>FAX</label>
            <b-input v-model="client_person.fax" type="text" />
          </div>
          <div class="form-inline">
            <label>Mail</label>
            <b-input v-model="client_person.mail" type="text" />
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-client-person')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button @click="delClientPerson" variant="danger" class="" :disabled="this.client_person.id == null">
            <b-icon icon="save"></b-icon>&nbsp;削除
          </b-button>
          <b-button variant="primary" @click="updateClientPerson">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id', 'top_title'],
  components: {
  },
  data: function () {
    return {
      client_company_id: 0,
      client_company: [],
      sel_user_list: [],
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "person_name", label: "名前", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "tel", label: "TEL", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "fax", label: "FAX", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "mail", label: "Mail", thStyle: {}, sortable: true, filter: 'input' },],

      //
      title: '',
      client_person: [],
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 350 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    setClientCompanyId: function (client_company_id) {
      this.client_company_id = client_company_id;
      this.getClientCompany();
    },
    // 顧客 - 会社 取得
    getClientCompany: function () {
      axios
        .get(`/master_client_company/get_client_company/${this.client_company_id}`)
        .then(response => {
          this.client_company = response.data.client_company_h;
          this.sel_user_list = response.data.sel_user_list;
          this.getClientPeople();
        })
    },
    // 顧客 更新
    updateClientCompany: function () {
      axios
        .patch(`/master_client_company/update_client_company`, { client_company: this.client_company })
        .then(response => {
          (this.client_company = response.data);
          alert("保存しました。");
        })
    },
    // 顧客 削除
    delClientCompany: function (client_company_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/master_client_company/del_client_company/${client_company_id}`)
          .then(response => {
            this.$root.$emit('bv::hide::modal', this.modal_id)
          })
      }
    },
    // 担当者 取得
    getClientPeople: function () {
      axios
        .get(`/master_client_company/get_client_people/${this.client_company.id}`)
        .then(response => {
          this.data_list = response.data.client_people;
        })
    },
    // 担当者モーダル 表示
    modalShow: function (client_person_id) {
      if (client_person_id == -1){
        this.title = "顧客担当者 > 追加"
      }else{
        this.title = "顧客担当者 > 編集"
      }
      axios
        .get(`/master_client_company/get_person/${client_person_id}`)
        .then(response => {
          (this.client_person = response.data);
          this.$root.$emit('bv::show::modal', 'modal-client-person')
        })
    },
    // 担当者 更新
    updateClientPerson: function () {
      axios
        .patch(`/master_client_company/update_client_person`, { client_company: this.client_company, client_person: this.client_person })
        .then(response => {
          (this.client_person = response.data);
          this.getClientPeople();
          this.$root.$emit('bv::hide::modal', 'modal-client-person')
        })
    },
    // 担当者 削除
    delClientPerson: function () {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/master_client_company/del_client_person/${this.client_person.id}`)
          .then(response => {
            alert("削除しました");
            this.$root.$emit('bv::hide::modal', 'modal-client-person');
            this.getClientPeople();
          })
      }
    },
    // 郵便番号検索
    searchAddress: function () {
      const zipCode = this.client_company.zip_code;
      axios
        .get(`https://api.zipaddress.net/?zipcode=${zipCode}`, { adapter: jsonpAdapter })
        .then(rs => {
          const response = rs.data;
          if (response.code == '400') {
            alert('郵便番号は数値7桁で入力してください');
          } else {
            this.client_company.address1 = response.pref + response.city + response.town
          }
        })
    },

  }
}
</script>

<style scoped></style>