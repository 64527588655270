/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from "app.vue"
import "packs/application.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// 販売
import MitumoriDetail from 'mitumori/detail.vue'
import Jyutyu from 'jyutyu/index.vue'
import JyutyuDetail from 'jyutyu/detail.vue'
import Nouhin from 'nouhin/index.vue'
import NouhinDetail from 'nouhin/detail.vue'
import Seikyu from 'seikyu/index.vue'
import SeikyuDetail from 'seikyu/detail.vue'
import Nyukin from 'nyukin/index.vue'
import NyukinDetail from 'nyukin/detail.vue'
// まとめ
import Matome from 'matome/index.vue'
// 仕入れ
import Zaiko from 'zaiko/index.vue'
import Hattyu from 'hattyu/index.vue'
import Total from 'total/index.vue'
// マスタ
import MasterCompany from 'master_company/index.vue'
import MasterItem from 'master_item/index.vue'
import MasterItemEtc from 'master_item/etc.vue'
import MasterItemType from 'master_item/item_type.vue'
import MasterItemTax from 'master_item/tax.vue'
import MasterClientCompany from 'master_client_company/index.vue'
import MasterClientCompanyDetail from 'master_client_company/detail.vue'
import MasterHattyuCompany from 'master_hattyu_company/index.vue'
import MasterHattyuCompanyDetail from 'master_hattyu_company/detail.vue'
//
import Header from 'components/header.vue'
import SelectItem from 'components/select_item.vue'
import SelectClientCompany from 'components/select_client_company.vue'
import BTableFilter from '../components/b_table_filter.vue';
import BTableFilterPager from '../components/b_table_filter_pager.vue';
import CsvImport from '../components/csv_import.vue';
 
import VCalendar from 'v-calendar'
Vue.use(VCalendar)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.filter('fmtNumberFilter', function (value) {
  if (!value) return '0'
  return value.toLocaleString();
})
const jsonpAdapter = require('axios-jsonp');
Vue.directive('scroll', {
  inserted: function (el, binding) {
      let f = function (evt) {
          if (binding.value(evt, el)) {
              el.removeEventListener('scroll', f)
          }
      }
      el.addEventListener('scroll', f)
  }
});

Vue.mixin({
  components: {
    Loading,
    // 販売
    MitumoriDetail,
    Jyutyu,
    JyutyuDetail,
    Nouhin,
    NouhinDetail,
    Seikyu,
    SeikyuDetail,
    Nyukin,
    NyukinDetail,
    //
    Matome,
    // 仕入れ
    Zaiko,
    Hattyu,
    Total,
    // マスタ
    MasterCompany,
    MasterItem,
    MasterItemEtc,
    MasterItemType,
    MasterItemTax,
    MasterClientCompany,
    MasterClientCompanyDetail,
    MasterHattyuCompany,
    MasterHattyuCompanyDetail,
    //
    Header,
    SelectItem,
    SelectClientCompany,
    BTableFilter,
    BTableFilterPager,
    CsvImport
  },
  computed: {
    filterFun: function () {
      return this.filter_val
    }
  },

  data: function () {
    return {
      maxWidth: document.documentElement.scrollWidth - 50 + "px",
      client_loading: false,
      item_loading: false,
      // permission
      pms_hanbai: 0,
      pms_nyukin: 0,
      pms_zaiko: 0,
      pms_hattyu: 0,
      pms_master_company: 0,
      pms_master_hattyu_company: 0,
      pms_master_client_company: 0,
      pms_master_item: 0,
    }
  },
  mounted() {
      
  },
  methods: {
    // ログインチェック
    checkLogin: function () {
      // 同期処理 return追加
        return axios
        .get(`/login/check_login`)
        .then(response => {
          if (!response.data.flg) {
             this.$router.push({ name: 'login', params: {} })
          }else{
            this.pms_hanbai = response.data.pms_hanbai;
            this.pms_nyukin = response.data.pms_nyukin;
            this.pms_zaiko = response.data.pms_zaiko;
            this.pms_hattyu = response.data.pms_hattyu;
            this.pms_master_company = response.data.pms_master_company;
            this.pms_master_hattyu_company = response.data.pms_master_hattyu_company;
            this.pms_master_client_company = response.data.pms_master_client_company;
            this.pms_master_item = response.data.pms_master_item;
          }
        })
    },
  
    fmtNumber: function (value) {
      return value.toLocaleString();
    },
    pdfView: function (pdfUrl) {
      var link = document.createElement('a')
      link.href = pdfUrl
      link.target = '_blank'
      link.click()
    },
  },
})


document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
})


