<template>
  <div>
    <b-modal :id="modal_id" title="納品" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 一覧 -->
      <div :style="'width: ' + maxWidth + '; overflow: scroll'">
        <div style="width: 1320px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_detail='toNouhinDetail' @on_delete='delNouhin'
            :filter_enable="true" />
        </div>
      </div>
    </b-modal>
    <!-- 納品 詳細 モーダル -->
    <NouhinDetail ref="child_nouhin_detail_teiki" @close_modal='closeDetailModal' :modal_id="'nouhin2nouhin_detail'" />
  </div>
</template>

<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "index", label: "", thStyle: { width: "50px" }, tdClass: 'text-center', sortable: false, filter: false },
        { key: "edit", label: "", thStyle: { width: "60px" }, tdClass: 'text-center', sortable: false, filter: false, detail: true, delete: false },
        { key: "m_code_ex", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input' },
        { key: "title", label: "品題", thStyle: { width: "180px" }, sortable: true, filter: 'input' },
        { key: "mitumori_on", label: "見積日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "jyutyu_on", label: "受注日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "nouhin_on", label: "納品日", thStyle: { width: "150px" }, sortable: true, filter: 'input' },
        { key: "client_company_name", label: "顧客", thStyle: { width: "140px" }, sortable: true, filter: 'input' },
        { key: "client_person_name", label: "顧客担当者", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "user_name", label: "担当者", thStyle: { width: "120px" }, sortable: true, filter: 'select' },
        { key: "status", label: "状態", thStyle: {}, sortable: true, filter: 'select' },
      ],
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 200 + "px"
    }
  },
  mounted() {
    // this.getNouhinList()
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    closeDetailModal: function () {
      this.getNouhinList();
    },
    // 納品一覧 取得
    getNouhinList: function () {
      axios
        .get('/nouhin/get_nouhin_list')
        .then(response => {
          this.data_list = response.data.nouhin_list;
          this.filter_list[9] = response.data.user_filter_list;
          this.filter_list[10] = response.data.status_filter_list;
          this.filter_val[9] = response.data.user_name;
        })
    },
    // 納品 詳細
    toNouhinDetail: function (nouhin_id) {
      this.$refs.child_nouhin_detail_teiki.setNouhinId(nouhin_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'nouhin2nouhin_detail');
      }, 100);
    },
    // 納品 削除
    delNouhin: function (nouhin_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/nouhin/del_nouhin/${nouhin_id}`)
          .then(response => {
            if (response.data.flg == false) {
              alert("この伝票は削除できません")
            } else {
              this.getNouhinList();
            }
          })
      }
    },
    // 商品 モーダル(新規、編集) 表示
    modalShow: function (nouhin_id) {
      axios
        .get(`/nouhin/get_nouhin/${nouhin_id}`)
        .then(response => {
          this.nouhin = response.data[0];
          this.sel_client_company = response.data[1]
          this.sel_client_person = response.data[2];
          this.sel_person = response.data[3]
          this.$root.$emit('bv::show::modal', 'modal-nouhin')
        })
    },
    // 顧客会社_選択 → 顧客担当者_選択 更新
    selClientPersonChange: function () {
      axios
        .get(`/nouhin/get_sel_client_person/${this.nouhin.client_company_id}`)
        .then(response => {
          this.sel_client_person = response.data;
        })
    },

  }
}
</script>

<style scoped></style>