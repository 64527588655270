<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal :id="modal_id" title="集計" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <div class="main_modal_div" style="min-height: 500px;">
        <div style="margin-bottom: 5px; " class="form-inline">
          <b-form-select v-model="on_type" style="width: 100px" @change="onFilterEx">
            <option v-for="option in sel_on_type" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          <v-date-picker v-model="date1" @input="onFilterEx" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;-&nbsp;
          <v-date-picker v-model="date2" @input="onFilterEx" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;&nbsp;
          <b-button v-if="!print1_load" @click="printTotal" variant="success" style="width:120px !important">
            <b-icon icon="printer"></b-icon>&nbsp;集計
          </b-button>
          <b-button v-if="print1_load" variant="success" disabled style="width:120px !important;">
            <b-spinner small></b-spinner>
            Loading...
          </b-button>
          &nbsp;&nbsp;
          <b-button v-if="!print2_load" @click="printTotalByUser" variant="success" style="width:140px !important">
            <b-icon icon="printer"></b-icon>&nbsp;担当者集計
          </b-button>
          <b-button v-if="print2_load" variant="success" disabled style="width:140px !important;">
            <b-spinner small></b-spinner>
            Loading...
          </b-button>
        </div>
        <!-- 一覧 -->
        <div>
          <div style="width: 1200px; ">
            <b-table-filter-pager :maxHeight="maxHeight" :filter_list="filter_list" :busy="busy" :filter_val="filter_val"
              :filter_val_sign="filter_val_sign" :filter_val_ex="filter_val_ex" :data_count="data_count"
              :data_list_field="data_list_field" :data_list="data_list" :currentPage="current_page"
              @on_page_change='onPageChange' @on_filter_ex='onFilterEx' :filter_enable="true" :nav_flg="true"
              :add_btn="false" :edit_disabled="pms_zaiko < 2" />
          </div>
        </div>
        <div class="form-inline" style="width: 1220px; margin-top: -45px; margin-left: -10px;">
          <label style="margin-left: 860px; ">合計数量</label>
          <b-input v-model="quantity_total" type="text" style="width: 80px; text-align: right;" disabled />
          <label style="margin-left: 10px; ">合計</label>
          <b-input v-model="price_total" type="text" style="width: 120px; text-align: right;" disabled />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      on_type: 3,
      sel_on_type: [{ value: 0, text: "見積日" }, { value: 1, text: "受注日" },
      { value: 2, text: "納品日" }, { value: 3, text: "請求日" }],
      date1: '',
      date2: '',
      print1_load: false,
      print2_load: false,
      // table
      price_total: 0,
      quantity_total: 0,
      current_page: 1,
      busy: false,
      isLoading: false,
      sort_by: '',
      sort_desc: true,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_sign: new Array(15),
      filter_val_ex: new Array(15),
      data_count: 0,
      data_list: [],
      data_list_field: [
        { key: "mitumori_on", label: "見積日", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "m_code", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "title", label: "品題", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type1", label: "品類", thStyle: { width: "90px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type2", label: "品目", thStyle: { width: "90px" }, sortable: true, filter: 'input_ex' },
        { key: "item_name", label: "商品名", thStyle: { width: "200px" }, sortable: true, filter: 'input_ex' },
        { key: "quantity", label: "数量", thStyle: { width: "70px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "price", label: "金額", thStyle: { width: "100px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "hattyu_company_name", label: "発注先", thStyle: { width: "130px" }, sortable: true, filter: 'select_ex2' },
        { key: "client_company_name", label: "顧客", thStyle: { width: "" }, sortable: true, filter: 'input_ex' },
      ],
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 300 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    //
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getTotalList();
    },
    //
    onFilterEx: function () {
      this.current_page = 1;
      this.getTotalList();
    },
    // 在庫一覧 取得
    getTotalList: function () {
      this.isLoading = true;
      axios
        .get('/total/get_total_list/', {
          params: {
            on_type: this.on_type, date1: this.date1, date2: this.date2,
            mitumori_on_ex: this.filter_val_ex[0], m_code_ex: this.filter_val_ex[1],
            title_ex: this.filter_val_ex[2], teiki_type1_ex: this.filter_val_ex[3],
            teiki_type2_ex: this.filter_val_ex[4], item_name_ex: this.filter_val_ex[5],
            quantity_ex: this.filter_val_ex[6], price_ex: this.filter_val_ex[7],
            hattyu_company_name_ex: this.filter_val_ex[8], client_company_name_ex: this.filter_val_ex[9],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list_field[0]["label"] = this.sel_on_type[this.on_type]["text"]
          this.data_list = response.data.mitumori_detail_list;
          this.data_count = response.data.mitumori_detail_cnt;
          this.price_total = response.data.price_total;
          this.quantity_total = response.data.quantity_total;
          this.date1 = response.data.date1;
          this.date2 = response.data.date2;
          this.filter_list[8] = response.data.hattyu_company_filter_list;
          this.mitumori_cnt = this.data_list.length;
          this.isLoading = false;
        })
    },
    // 集計
    printTotal: function () {
      if (((this.filter_val_ex[0] == undefined) || (this.filter_val_ex[0] == "")) &&
        ((this.filter_val_ex[1] == undefined) || (this.filter_val_ex[1] == "")) &&
        ((this.filter_val_ex[2] == undefined) || (this.filter_val_ex[2] == "")) &&
        ((this.filter_val_ex[3] == undefined) || (this.filter_val_ex[3] == "")) &&
        ((this.filter_val_ex[4] == undefined) || (this.filter_val_ex[4] == "")) &&
        ((this.filter_val_ex[5] == undefined) || (this.filter_val_ex[5] == "")) &&
        ((this.filter_val_ex[6] == undefined) || (this.filter_val_ex[6] == "")) &&
        ((this.filter_val_ex[7] == undefined) || (this.filter_val_ex[7] == "")) &&
        ((this.filter_val_ex[8] == undefined) || (this.filter_val_ex[8] == "")) &&
        ((this.filter_val_ex[9] == undefined) || (this.filter_val_ex[9] == ""))) {
        if (this.on_type == 3) {
          this.print1_load = true;
          axios
            .get('/total/print_total/', {
              params: {
                date1: this.date1, date2: this.date2,
                title_ex: this.filter_val_ex[2], teiki_type1_ex: this.filter_val_ex[3],
                teiki_type2_ex: this.filter_val_ex[4], item_name_ex: this.filter_val_ex[5],
                hattyu_company_name_ex: this.filter_val_ex[8],
              }
            })
            .then(response => {
              if (response.data.flg) {
                this.pdfView(response.data.url);
                this.print1_load = false;
              } else {
                alert(response.data.msg);
              }
              this.print1_load = false;
            }).catch((error) => {
              alert("error")
              this.print1_load = false;
            })
        } else {
          alert("集計の印刷は請求日で絞り込みをしてください")
        }
      } else {
        alert("集計の印刷は絞り込みをしないでください")
      }

    },
    // 担当者集計
    printTotalByUser: function () {
      if (((this.filter_val_ex[0] == undefined) || (this.filter_val_ex[0] == "")) &&
        ((this.filter_val_ex[1] == undefined) || (this.filter_val_ex[1] == "")) &&
        ((this.filter_val_ex[6] == undefined) || (this.filter_val_ex[6] == "")) &&
        ((this.filter_val_ex[7] == undefined) || (this.filter_val_ex[7] == "")) &&
        ((this.filter_val_ex[9] == undefined) || (this.filter_val_ex[9] == ""))) {
        if (this.on_type == 3) {
          this.print2_load = true;
          axios
            .get('/total/print_total_by_user/', {
              params: {
                date1: this.date1, date2: this.date2,
                title_ex: this.filter_val_ex[2], teiki_type1_ex: this.filter_val_ex[3],
                teiki_type2_ex: this.filter_val_ex[4], item_name_ex: this.filter_val_ex[5],
                hattyu_company_name_ex: this.filter_val_ex[8],
              }
            })
            .then(response => {
              if (response.data.flg) {
                this.pdfView(response.data.url);
                this.print2_load = false;
              } else {
                alert(response.data.msg);
              }
              this.print2_load = false;
            }).catch((error) => {
              alert("error")
              this.print2_load = false;
            })
        } else {
          alert("担当者集計の印刷は請求日で絞り込みをしてください")
        }
      } else {
        alert("担当者集計の印刷は品題、品類、品目、商品名、発注先のみで絞り込みをしてください")
      }
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getTotalList();
    }

  }
}
</script>

<style scoped></style>