<template>
  <header>
    <div>
      <div>
        <b-navbar toggleable="lg" type="dark" variant="dark" style="" class="navbar-expand">
          <b-navbar-brand href="#/main" @click="windowReload">販売管理</b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mr-auto">
              <!-- 販売 -->
              <!-- <b-nav-item @click="pageChange('main')" :class="pms_hanbai > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_hanbai == 0">
                <b-icon icon="list-check" />
                販売</b-nav-item> -->
              <!-- 一括 -->
              <b-nav-item @click="menuSelect('matome')" :class="pms_nyukin > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_hanbai == 0" v-b-tooltip.hover.bottom.v-info="tooltip_matome">
                <b-icon icon="card-checklist" />
                一括
              </b-nav-item>
              <!-- 入金 -->
              <b-nav-item @click="menuSelect('nyukin')" :class="pms_nyukin > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_nyukin == 0" v-b-tooltip.hover.bottom.v-info="tooltip_urikake">
                <b-icon icon="currency-yen" />
                売掛
              </b-nav-item>
              <!-- 仕入れ -->
              <b-nav-item @click="menuSelect('zaiko')" :class="pms_zaiko > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_zaiko == 0" v-b-tooltip.hover.bottom.v-info="tooltip_zaiko">
                <b-icon icon="grid3x3-gap-fill" />
                在庫
              </b-nav-item>
              <b-nav-item @click="menuSelect('hattyu')" :class="pms_hattyu > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_hattyu == 0" v-b-tooltip.hover.bottom.v-info="tooltip_siire">
                <!-- title=""> -->
                <b-icon icon="truck" />
                仕入
              </b-nav-item>
              <b-nav-item @click="menuSelect('total')" :class="pms_zaiko > 0 ? ['active', 'b-nav'] : []"
                :disabled="pms_zaiko == 0" v-b-tooltip.hover.bottom.v-info="tooltip_syuukei">
                <b-icon icon="calculator" />
                集計
              </b-nav-item>
              <!-- マスタ -->
              <b-nav-item-dropdown text="マスタ"
                :class="(pms_master_company > 0) || (pms_master_hattyu_company > 0) || (pms_master_hattyu_company > 0) || (pms_master_item > 0) ? ['active', 'b-nav'] : []"
                :disabled="(pms_master_company == 0) && (pms_master_hattyu_company == 0) && (pms_master_hattyu_company == 0) && (pms_master_item == 0)"
                v-b-tooltip.hover.bottom.v-info="tooltip_master">
                <template slot="button-content">
                  <b-icon icon="stack" />&nbsp;マスタ
                </template>
                <b-dropdown-item @click="menuSelect('master_company')" :disabled="pms_master_company == 0">
                  <b-icon icon="house" />
                  自社
                </b-dropdown-item>
                <b-dropdown-item @click="menuSelect('master_hattyu_company')"
                  :disabled="pms_master_hattyu_company == 0">
                  <b-icon icon="bank" />
                  発注先
                </b-dropdown-item>
                <b-dropdown-item @click="menuSelect('master_client_company')"
                  :disabled="pms_master_client_company == 0">
                  <b-icon icon="bank2" />
                  顧客
                </b-dropdown-item>
                <b-dropdown-item @click="menuSelect('master_item')" :disabled="pms_master_item == 0">
                  <b-icon icon="box-seam" />
                  商品
                </b-dropdown-item>
                <b-dropdown-item @click="menuSelect('master_item_etc')" :disabled="pms_master_item == 0">
                  <b-icon icon="asterisk" />
                  その他
                </b-dropdown-item>
              </b-nav-item-dropdown>

            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown :class="['active', 'b-nav']" right>
                <template slot="button-content">
                  <b-icon icon="person" />&nbsp;{{ user2.name1 + ' ' + (user2.name2 || "") }}
                </template>
                <b-dropdown-item @click="myPageShow()">
                  <b-icon icon="person-lines-fill" />
                  マイページ
                </b-dropdown-item>
                <b-dropdown-item @click="logout()">
                  <b-icon icon="box-arrow-right" />
                  ログアウト
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
      <!-- モーダル -->
      <b-modal id="modal-mypage" title="マイページ " :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>ID</label>
            <b-input v-model="user.login_id" type="text" />
          </div>
          <div class="form-inline">
            <label>姓</label>
            <b-input v-model="user.name1" type="text" style="width: 170px;" />
            &nbsp;
            <label>名</label>
            <b-input v-model="user.name2" type="text" style="width: 170px;" />
          </div>
          <div class="form-inline">
            <label>セイ</label>
            <b-input v-model="user.name1_kana" type="text" style="width: 170px;" />
            &nbsp;
            <label>メイ</label>
            <b-input v-model="user.name2_kana" type="text" style="width: 170px;" />
          </div>
          <div class="form-inline">
            <label>TEL</label>
            <b-input v-model="user.tel" type="text" />
          </div>
          <div class="form-inline">
            <label>eMail</label>
            <b-input v-model="user.mail" type="text" />
          </div>
          <b-button variant="primary" @click="updateUser" style="margin-left: 350px;">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
          <hr />
          <div class="form-inline">
            <label>パスワード</label>
            <b-input v-model="user.password" type="text" style="width: 150px !important" />
          </div>
          <div class="form-inline">
            <label>パスワード再入力</label>
            <b-input v-model="user.password_confirmation" type="text" style="width: 150px !important" />
          </div>
          <b-button variant="primary" @click="updatePass" style="width: 170px !important; margin-left: 280px;">
            <b-icon icon="save"></b-icon>&nbsp;パスワード変更
          </b-button>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-mypage')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
        </div>
      </b-modal>
      <!-- ==================================================================== -->
      <!-- 販売モーダル -->
      <!-- ==================================================================== -->
      <!-- 見積 詳細 -->
      <MitumoriDetail ref="child_mitumori_detail" :modal_id="'main-mitumori_detail'" @close_modal='closeModal' />
      <!-- まとめ -->
      <Matome ref="child_matome" :modal_id="'main-matome'" @close_modal='closeModal' />
      <!-- 受注 -->
      <Jyutyu ref="child_jyutyu" :modal_id="'main-jyutyu'" @close_modal='closeModal' />
      <!-- 受注 詳細 -->
      <JyutyuDetail ref="child_jyutyu_detail" :modal_id="'main-jyutyu_detail'" @close_modal='closeModal' />
      <!-- 納品 -->
      <Nouhin ref="child_nouhin" :modal_id="'main-nouhin'" @close_modal='closeModal' />
      <!-- 納品 詳細 -->
      <NouhinDetail ref="child_nouhin_detail" :modal_id="'main-nouhin_detail'" @close_modal='closeModal' />
      <!-- 請求 -->
      <Seikyu ref="child_seikyu" :modal_id="'main-seikyu'" @close_modal='closeModal' />
      <!-- 請求 詳細 -->
      <SeikyuDetail ref="child_seikyu_detail" :modal_id="'main-seikyu_detail'" @close_modal='closeModal' />
      <!-- 入金 -->
      <Nyukin ref="child_nyukin" :modal_id="'main-nyukin'" @close_modal='closeModal' />
      <NyukinDetail ref="child_nyukin_detail" :modal_id="'main-nyukin_detail'" @close_modal='closeModal' />
      <!-- 在庫 -->
      <Zaiko ref="child_zaiko" :modal_id="'main-zaiko'" />
      <!-- 発注 -->
      <Hattyu ref="child_hattyu" :modal_id="'main-hattyu'" @close_modal='closeModal' />
      <!-- 集計 -->
      <Total ref="child_total" :modal_id="'main-total'" />
      <!-- ==================================================================== -->
      <!-- マスタ -->
      <!-- ==================================================================== -->
      <!-- 自社 -->
      <MasterCompany ref="child_master_company" :modal_id="'main-master_company'" />
      <!-- 発注先 -->
      <MasterHattyuCompany ref="child_master_hattyu_company" :modal_id="'main-master_hattyu_company'" />
      <!-- 顧客 -->
      <MasterClientCompany ref="child_master_client_company" :modal_id="'main-master_client_company'"
        @close_modal='closeModal' />
      <!-- 商品 -->
      <MasterItem ref="child_master_item" :modal_id="'main-master_item'" />
      <MasterItemEtc ref="child_master_item_etc" :modal_id="'main-master_item_etc'" />
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  mounted() {
    this.checkLogin();
    this.getMyPage();
  },
  data: function () {
    return {
      tooltip_matome: { html: true, title: '・選択した複数の伝票を一括で「複写」「置換」「受注確定」「納品済」「請求済」を処理します。' },
      tooltip_urikake: { html: true, title: '・請求伝票からの入金処理をします。' },
      tooltip_zaiko: { html: true, title: "・商品の在庫編集処理や確定した発注伝票からの確保数を確認をします。" },
      tooltip_siire: { html: true, title: '・確定した発注伝票より在庫不足分の商品やその発注先を確認します。' },
      tooltip_syuukei: { html: true, title: '・商品や担当営業別の売上集計を確認します。' },
      tooltip_master: { html: true, title: '・「自社」「発注先」「顧客」「商品」のマスタ情報を編集します。' },
      user: [],
      user2: [],

    }
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    pageChange: function (menuName) {
      this.$router.push({ name: menuName }).catch(err => { })
    },
    windowReload: function () {
      window.location.reload();
    },
    menuSelect: function (menuName) {
      this.$root.$emit('bv::show::modal', 'main-' + menuName);
      setTimeout(() => {
        switch (menuName) {
          case 'matome':
            this.$refs.child_matome.onSelFilterEx();
            break;
          case 'jyutyu':
            this.$refs.child_jyutyu.getJyutyuList();
            break;
          case 'jyutyu_teiki':
            this.$refs.child_jyutyu_teiki.getJyutyuList();
            break;
          case 'nouhin':
            this.$refs.child_nouhin.getNouhinList();
            break;
          case 'nouhin_teiki':
            this.$refs.child_nouhin_teiki.getNouhinList();
            break;
          case 'seikyu':
            this.$refs.child_seikyu.getNouhinList();
            break;
          case 'nyukin':
            this.$refs.child_nyukin.onFilterEx();
            break;
          case 'nyukin_detail':
            break;
          case 'zaiko':
            this.$refs.child_zaiko.onFilterEx();
            break;
          case 'hattyu':
            this.$refs.child_hattyu.onFilterEx();
            break;
          case 'total':
            this.$refs.child_total.onFilterEx();
            this.$root.$emit('bv::show::modal', 'main-total');
            break;
          case 'master_company':
            this.$refs.child_master_company.getCompany();
            break;
          case 'master_hattyu_company':
            this.$refs.child_master_hattyu_company.onFilterEx();
            break;
          case 'master_client_company':
            this.$refs.child_master_client_company.onFilterEx();
            break;
          case 'master_item':
            this.$refs.child_master_item.onFilterEx();
            break;
          case 'master_item_etc':
            // this.$refs.child_master_item_type.onFilterEx();
            break;
        }
      }, 0);
    },
    // ===============================================================
    // Header
    // ===============================================================
    // マイページ  モーダル 表示
    getMyPage: function () {
      axios
        .get(`/master_company/get_mypage`)
        .then(response => {
          this.user = response.data.user;
          this.user2 = response.data.user2;
        })
    },
    myPageShow: function () {
      this.$root.$emit('bv::show::modal', 'modal-mypage')
    },
    // マイページ(user) 更新
    updateUser: function () {
      axios
        .patch(`/master_company/update_my_page`, { user: this.user })
        .then(response => {
          alert("保存しました");
          this.getMyPage();
        })
    },
    // マイページ(pass) 更新
    updatePass: function () {
      if (confirm("パスワードを変更しますか？")) {
        axios
          .patch(`/master_company/change_password`, { user: this.user })
          .then(response => {
            if (response.data.flg) {
              alert("変更しました");
            } else {
              alert(response.data.msg);
            }
          })
      }
    },
    // logout
    logout: function () {
      if (confirm("ログアウトしますか？")) {
        axios
          .post('/login/logout')
          .then(rs => {
            this.$router.push({ name: 'login', params: {} })
          })
      }
    },
  }
}
</script>
<style scoped>
.b-nav {
  min-width: 90px;
  text-align: left;
}
</style>