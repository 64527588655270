<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal :id="modal_id" title="仕入" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered>
      <div class="main_modal_div" style="min-height: 500px;">
        <!-- 条件 -->
        <div style="margin-bottom: 5px; " class="form-inline">
          <label style="">発注日&nbsp;</label>
          <v-date-picker v-model="date1" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;-&nbsp;
          <v-date-picker v-model="date2" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;&nbsp;
          <b-form-checkbox id="checkbox-flg1" v-model="item_sum_flg" name="checkbox-flg1" @change="getHattyuList" v-b-tooltip.hover.bottom.v-info="tooltip_matome">
            まとめて表示
          </b-form-checkbox>
          &nbsp;&nbsp;
          <b-button v-if="!hattyu_load" @click="printHattyu" variant="success" style="width:120px !important">
            <b-icon icon="printer"></b-icon>&nbsp;発注書
          </b-button>
          <b-button v-if="hattyu_load" variant="success" disabled style="width:120px !important">
            <b-spinner small></b-spinner>
            Loading...
          </b-button>
        </div>
        <!-- 一覧 -->
        <div>
          <div style="width: 1200px; ">
            <b-table-filter-pager :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
              :filter_val_ex="filter_val_ex" :data_list_field="data_list_field" :data_list="data_list"
              :data_count="data_count" :busy="busy" :currentPage="current_page" @on_sort="onSort"
              @on_page_change='onPageChange' @on_edit='onEdit' @on_hattyu_chk='onHattyuChk' @on_add='onEdit'
              @on_kensyu_chk='onKensyuChk' @on_filter_ex='onFilterEx' :filter_enable="true" :nav_flg="true"
              :edit_disabled="pms_hattyu < 2" />
          </div>
        </div>
        <div class="form-inline" style="width: 1200px; margin-top: -45px;">
          <label style="margin-left: 990px; ">合計</label>
          <b-input v-model="hattyu_tanka_total" type="text" style="width: 140px; text-align: right;" disabled />
        </div>

      </div>
      <!-- モーダル 発注  -->
      <b-modal id="modal-hattyu" :title="title" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>発注日</label>
            <v-date-picker v-model="hattyu.hattyu_on" class="date_picker">
              <template v-slot="{ inputValue, inputEvents }">
                <b-input :value="inputValue" v-on="inputEvents" readonly />
              </template>
            </v-date-picker>
          </div>
          <div class="form-inline">
            <label>伝票番号</label>
            <b-input v-model="hattyu.m_code_ex" type="text" style="width: 200px;" readonly />
          </div>
          <div class="form-inline">
            <!-- 商品選択 -->
            <SelectItem @on_select='onItemSelect' />
            <label>商品</label>
            <span v-if="hattyu.m_code_ex != ''">
              <b-input v-model="hattyu.item_name" type="text" style="width:350px;" readonly />
              <b-button v-if="!item_loading" variant="info" class="icon_btn" disabled>
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </b-button>
              <b-button v-if="item_loading" variant="info" class="icon_btn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </b-button>
            </span>
            <span v-else>
              <b-input v-model="hattyu.item_name" type="text" style="width:350px; background-color: white;"
                @click="selectItemModalShow" readonly />
              <b-button v-if="!item_loading" @click="selectItemModalShow" variant="info" class="icon_btn">
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </b-button>
              <b-button v-if="item_loading" variant="info" class="icon_btn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </b-button>
            </span>
          </div>
          <div class="form-inline">
            <label>数量</label>
            <b-input v-model="hattyu.quantity" type="number" :readonly="hattyu.m_code_ex != ''" />
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-hattyu')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="danger" @click="onDelete" v-bind:disabled="hattyu.delete_disabled || hattyu.id == null">
            <b-icon icon="trash"></b-icon>&nbsp;削除
          </b-button>
          <b-button variant="primary" @click="SaveHattyu">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      offset: 4,
      hattyu: [],
      item_sum_flg: true,
      hattyu_load: false,
      tooltip_matome: { html: true, title: '同じ「商品」を「発注日」毎にまとめて表示' },
      // table
      hattyu_tanka_total: 0,
      current_page: 1,
      busy: false,
      isLoading: false,
      sort_by: '',
      sort_desc: true,
      data_count: 0,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_ex: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "quantity", label: "納品", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-right', sortable: false, filter: 'input_ex' },
        { key: "zaiko_quantity", label: "確保", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-right', sortable: false, filter: 'input_ex' },
        { key: "hattyu_chk", label: "発注", thStyle: { width: "100px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_ex2' },
        { key: "kensyu_chk", label: "検収", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_ex2' },
        { key: "m_code_ex", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "hattyu_on", label: "発注日", thStyle: { width: "140px" }, sortable: true, filter: 'select_ex2' },
        { key: "client_company", label: "顧客", thStyle: { width: "150px" }, sortable: true, filter: 'input_ex' },
        { key: "item_code", label: "商品コード", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "item_name", label: "商品名", thStyle: { width: "170px" }, sortable: true, filter: 'input_ex' },
        { key: "hattyu_company", label: "発注先", thStyle: { width: "" }, sortable: true, filter: 'select_ex2' },
      ],
      // モーダル
      title: '',
      sel_item_type: [],
      sel_item: [],
      sel_hattyu_company: [],
      //
      date1: '',
      date2: '',

      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 300 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getHattyuList();
    },
    //
    onDateChange: function () {
      this.current_page = 1;
      this.getHattyuList();
    },
    //
    onFilterEx: function () {
      this.current_page = 1;
      this.getHattyuList();
    },
    // 一覧 取得
    getHattyuList: function () {
      this.isLoading = true;
      axios
        .get('/hattyu/get_hattyu_list', {
          params: {
            date1: this.date1, date2: this.date2, item_sum_flg: this.item_sum_flg,
            quantity_ex: this.filter_val_ex[1], zaiko_quantity_ex: this.filter_val_ex[2],
            hattyu_chk_ex: this.filter_val_ex[3], kensyu_chk_ex: this.filter_val_ex[4],
            m_code_ex: this.filter_val_ex[5], hattyu_on_ex: this.filter_val_ex[6],
            client_company_ex: this.filter_val_ex[7], item_code_ex: this.filter_val_ex[8],
            item_name_ex: this.filter_val_ex[9], hattyu_company_ex: this.filter_val_ex[10],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list = response.data.hattyu_list;
          this.hattyu_tanka_total = response.data.hattyu_tanka_total;
          this.date1 = response.data.date1;
          this.date2 = response.data.date2;
          this.data_count = response.data.hattyu_count;
          this.filter_list[3] = response.data.chk_filter_list;
          this.filter_list[4] = response.data.chk_filter_list;
          this.filter_list[5] = response.data.nouhin_fillter_list;
          this.filter_list[6] = response.data.hattyu_on_filter_list;
          this.filter_list[7] = response.data.client_company_filter_list;
          this.filter_list[8] = response.data.item_code_filter_list
          this.filter_list[9] = response.data.item_filter_list;
          this.filter_list[10] = response.data.hattyu_company_filter_list;
          this.isLoading = false;
        })
    },
    //  モーダル(新規、編集) 表示
    onEdit: function (hattyu_id) {
      if (hattyu_id == -1){
        this.title = "仕入 > 追加"
      }else{
        this.title = "仕入 > 編集"
      }
      axios
        .get(`/hattyu/get_hattyu/${hattyu_id}`)
        .then(response => {
          this.hattyu = response.data.hattyu_h;
          this.sel_item_type = response.data.sel_item_type;
          this.sel_item = response.data.sel_item;
          this.sel_hattyu_company = response.data.sel_hattyu_company;
          this.$root.$emit('bv::show::modal', 'modal-hattyu')
        })
    },
    // 商品分類_選択 → 商品 更新
    selItemTypeChange: function () {
      axios
        .get(`/hattyu/get_sel_item/${this.hattyu.item_type_id}`)
        .then(response => {
          this.sel_item = response.data.sel_item;
        })
    },
    // モーダル 保存
    SaveHattyu: function () {
      axios
        .patch(`/hattyu/save_hattyu`, { hattyu: this.hattyu, date1: this.date1, date2: this.date2 })
        .then(response => {
          if (response.data.flg) {
            this.getHattyuList();
            this.$root.$emit('bv::hide::modal', 'modal-hattyu')
          } else {
            alert(response.data.msg)
          }
        })
    },
    //  削除
    onDelete: function () {
      if (confirm('削除しますか？')) {
        axios
          .delete('/hattyu/del_hattyu/', {
            params: { id: this.hattyu.id, date1: this.date1, date2: this.date2 }
          })
          .then(response => {
            if (response.data.flg) {
              alert('削除しました');
              this.$root.$emit('bv::hide::modal', 'modal-hattyu')
              this.getHattyuList();
            } else {
              alert('自動作成された発注は削除できません')
            }
          })
      }
    },
    // 発注書 印刷
    printHattyu: function () {
      this.hattyu_load = true;
      var offset = 4;
      axios
        .get(`/hattyu/print_hattyu`, {
          params: {
            date1: this.date1, date2: this.date2, item_sum_flg: this.item_sum_flg,
            filter_value0: this.filter_val[0 + offset], filter_value3: this.filter_val[1 + offset],
            filter_value2: this.filter_val[2 + offset], filter_value5: this.filter_val[3 + offset],
            filter_value4: this.filter_val[4 + offset], filter_value7: this.filter_val[5 + offset],
            filter_value6: this.filter_val[6 + offset], filter_value9: this.filter_val[7 + offset],
            filter_value8: this.filter_val[8 + offset]
          }
        })
        .then(response => {
          if (response.data.flg) {
            this.pdfView(response.data.url);
          } else {
            alert("印刷する発注書がありません")
          }
          this.hattyu_load = false;
        })
        .catch((error) => {
          console.log(error);
          his.hattyu_load = false;
        })
    },
    // =======================================================
    // 商品選択モーダル表示
    selectItemModalShow: function () {
      this.item_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-item')
        this.item_loading = false;
      }, 50);
    },
    // 商品選択
    onItemSelect: function (item_id) {
      axios
        .get(`/mitumori/get_tanka/${item_id}`)
        .then(response => {
          this.hattyu.item_id = response.data.item.id;
          this.hattyu.item_name = response.data.item.item_name;
          this.hattyu.item_type_id = response.data.item.item_type_id;
          this.hattyu.quantity = response.data.quantity;
          this.$root.$emit('bv::hide::modal', 'select-item')
        })
    },
    // =======================================================
    // 発注チェック
    onHattyuChk: function (hattyu_id, event) {
      if (event) {
        if (confirm('発注済みにしますか？')) {
          axios
            .get("/hattyu/set_hattyu/", { params: { id: hattyu_id, item_sum_flg: this.item_sum_flg } })
            .then(response => {
              this.getHattyuList();
            })
        } else {
          this.getHattyuList();
        }
      } else {
        if (confirm('発注を取り消しますか？')) {
          axios
            .get("/hattyu/set_hattyu_cancel/", { params: { id: hattyu_id, item_sum_flg: this.item_sum_flg } })
            .then(response => {
              if (response.data.flg === false) {
                alert("検収済みの発注は取り消しできません");
              }
              this.getHattyuList();
            })
        } else {
          this.getHattyuList();
        }
      }
    },
    // 検収チェック
    onKensyuChk: function (item_id, event) {
      if (event) {
        if (confirm('検収済みにしますか？')) {
          axios
            .get("/hattyu/set_kensyu/", { params: { id: item_id, item_sum_flg: this.item_sum_flg } })
            .then(response => {
              if (response.data.flg) {
                this.getHattyuList();
              } else {
                alert('発注後に実行してください。')
                this.getHattyuList();
              }
            })
        }
      } else {
        if (confirm('検収を取り消しますか？')) {
          axios
            .get("/hattyu/set_kensyu_cancel/", { params: { id: item_id, item_sum_flg: this.item_sum_flg } })
            .then(response => {
              if (response.data.flg === false) {
                alert("納品済みの検収は取り消しできません");
              }
              this.getHattyuList();
            })
        } else {
          this.getHattyuList();
        }
      }
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getHattyuList();
    }
  }
}
</script>

<style scoped></style>