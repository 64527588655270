<template>
  <div>
    <!-- 一覧 -->
    <div>
      <div style="overflow: auto;">
        <div style="width: 250px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_edit='onEdit' @on_delete='onDelete'
            :filter_enable="true" :edit_disabled="pms_master_item < 2" />
        </div>
      </div>
      <div style="margin: 5px 0px 0px 0px">
        {{ data_list.length }}件&nbsp;&nbsp;
        <b-button @click="onEdit(-1)" ref="btnShow" variant="info" class="icon_btn_w" :disabled="pms_master_item < 2">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </div>
    <!-- モーダル tax  -->
    <b-modal id="modal-tax" :title="title" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" hide-footer centered>
      <div class="modal_div">
        <div class="form-inline">
          <label>税率</label>
          <b-input v-model="tax.tax" type="number" style="width: 300px" />
        </div>
        <div class="form-inline">
            <label>種別</label>
            <b-form-select v-model="tax.tax_type" style="width: 200px">
              <option v-for="option in sel_tax_type" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            <!-- <b-input v-model="mitumori_detail.tax" type="number" /> -->
          </div>
      </div>
      <div class="modal-footer">
        <b-button @click="$bvModal.hide('modal-tax')">
          <b-icon icon="x"></b-icon>取消
        </b-button>
        <b-button @click="onDelete" variant="danger" class="" :disabled="this.tax.id == null">
          <b-icon icon="save"></b-icon>&nbsp;削除
        </b-button>
        <b-button variant="primary" @click="onSave">
          <b-icon icon="save"></b-icon>&nbsp;保存
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

// vue-good-table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // table
      current_page: 1,
      busy: false,
      sort_by: '',
      sort_desc: true,
      where: "",
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_sign: new Array(15),
      data_list: [],
      data_count: 0,
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "tax", label: "税率", thStyle: {}, tdClass: 'text-right', sortable: true, filter: 'input', edit: true },
        { key: "tax_type", label: "種別", thStyle: {}, sortable: true, filter: 'input', edit: true },
      ],
      // modal
      title: '',
      tax: [],
      sel_tax_type: [{ value: 0, text: "外税" }, { value: 1, text: "内税" }],
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 250 + "px"
    }
  },
  mounted() {
    this.checkLogin();
    this.onFilterEx();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    onFilterEx: function () {
      this.sel_id = []
      this.current_page = 1;
      this.getDataList();
    },
    // 一覧 取得
    getDataList: function () {
      this.busy = true
      axios
        .get('/master_item/get_tax_list/', {
          params: {
          }
        })
        .then(response => {
          this.data_list = response.data.data_list;
          this.busy = false
        })
    },
    // 編集
    onEdit: function (id) {
      if (id == -1){
        this.title = "税率 > 追加"
      }else{
        this.title = "税率 > 編集"
      }
      axios
        .get(`/master_item/get_tax/${id}`)
        .then(response => {
          this.tax = response.data.data;
          this.$root.$emit('bv::show::modal', 'modal-tax')
        })
    },
    // 削除
    onDelete: function () {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/master_item/del_tax/${this.tax.id}`)
          .then(response => {
            if (response.data.flg) {
              alert("削除しました。");
              this.$root.$emit('bv::hide::modal', 'modal-tax')
              this.getDataList();
            }else{
              alert(response.data.msg);
            }
          })
      }
    },
    // 保存
    onSave: function () {
      axios
        .patch(`/master_item/save_tax`, { tax: this.tax })
        .then(response => {
          this.getDataList();
          this.$root.$emit('bv::hide::modal', 'modal-tax')
        })
    },
    // ======================================================================================
    // モーダル保存
    onUpdate: function () {
    },
  }
}
</script>

<style scoped></style>