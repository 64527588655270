<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <b-modal :id="modal_id" title="在庫" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <div style="min-height: 500px;">
        <!-- 一覧 -->
        <div>
          <div style="width: 1200px; ">
            <b-table-filter-pager :maxHeight="maxHeight" :filter_list="filter_list" :busy="busy"
              :filter_val="filter_val" :filter_val_sign="filter_val_sign" :filter_val_ex="filter_val_ex"
              :data_count="data_count" :data_list_field="data_list_field" :data_list="data_list"
              :currentPage="current_page" @on_page_change='onPageChange' @on_sort="onSort" @on_edit='onEdit'
              @on_delete='onDelete' @on_filter_ex='onFilterEx' :filter_enable="true" :nav_flg="true" :add_btn="false"
              :edit_disabled="pms_zaiko < 2" />
          </div>
        </div>
        <div class="form-inline" style="margin: -45px 0 0 860px">
          <b-button v-if="!export_flg" @click="CsvExport" variant="primary"
            style="width: 135px !important; padding: 8px 0px;">
            <b-icon icon="file-earmark-arrow-up"></b-icon>&nbsp;エクスポート
          </b-button>
          <b-button v-if="export_flg" variant="primary" disabled style=" width:135px !important; padding: 8px 0px;">
          <b-spinner small></b-spinner>
          Loading...
        </b-button>
          <label style=" ">&nbsp;&nbsp;&nbsp;&nbsp;合計</label>
          <b-input v-model="hattyu_tanka_total" type="text" style="width: 120px; text-align: right;" disabled />
        </div>
      </div>
      <!-- モーダル 在庫  -->
      <b-modal id="modal-zaiko" title="在庫 > 編集" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label style="width: 70px; text-align: left; display:block;">数量</label>
            <b-input v-model="zaiko.quantity" type="number" readonly />
          </div>
          <div class="form-inline">
            <label style="width: 70px; text-align: left; display:block;">確保数量</label>
            <b-input v-model="zaiko.reserve_quantity" type="number" readonly />
          </div>
          <div class="form-inline">
            <label style="width: 70px; text-align: left; display:block;">増減数</label>
            <b-input v-model="zaiko_plus" type="number" />
            <b-button variant="primary" @click="updateZaiko(1)">
              <b-icon icon="save"></b-icon>&nbsp;保存
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      zaiko: [],
      number: 1,
      zaiko_plus: 0,
      zaiko_minus: 0,
      hattyu_tanka_total: 0,
      where: "",
      order: "",
      // table
      current_page: 1,
      busy: false,
      isLoading: false,
      sort_by: '',
      sort_desc: true,
      filter_list: new Array(15),
      filter_val: new Array(15),
      filter_val_sign: new Array(15),
      filter_val_ex: new Array(15),
      data_count: 0,
      data_list: [],
      export_flg: false,
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "item_code", label: "商品コード", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "item_type", label: "商品分類", thStyle: { width: "130px" }, sortable: true, filter: 'select_ex2' },
        { key: "hattyu_company_name", label: "発注先", thStyle: { width: "150px" }, sortable: true, filter: 'select_ex2' },
        { key: "item_name", label: "商品名", thStyle: { width: "250px" }, sortable: true, filter: 'input_ex' },
        { key: "hattyu_cycle", label: "区分", thStyle: { width: "70px" }, tdClass: 'text-center', sortable: true, filter: 'select_ex2' },
        { key: "quantity", label: "数量", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'input_num' },
        { key: "reserve_quantity", label: "確保", thStyle: { width: "70px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "hattyu_tanka", label: "仕入れ単価", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
        { key: "hattyu_tanka_sum", label: "合計", thStyle: {}, tdClass: 'text-right', sortable: true, filter: 'input_ex' },
      ],
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 270 + "px"
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    //
    onPageChange: function (current_page) {
      this.current_page = current_page;
      this.getZaikoList();
    },
    //
    onFilterEx: function () {
      this.current_page = 1;
      this.getZaikoList();
    },
    // 在庫一覧 取得
    getZaikoList: function () {
      this.isLoading = true;
      axios
        .get('/zaiko/get_zaiko_list/', {
          params: {
            item_code_ex: this.filter_val_ex[1], item_type_id_ex: this.filter_val_ex[2],
            hattyu_company_id_ex: this.filter_val_ex[3], item_name_ex: this.filter_val_ex[4],
            hattyu_cycle_ex: this.filter_val_ex[5],
            quantity_ex: this.filter_val_ex[6], quantity_sign: this.filter_val_sign[6],
            reserve_quantity_ex: this.filter_val_ex[7], reserve_quantity_sign: this.filter_val_sign[7],
            hattyu_tanka_ex: this.filter_val_ex[8],
            hattyu_tanka_sum_ex: this.filter_val_ex[9],
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.data_list = response.data.zaiko_list;
          this.data_count = response.data.zaiko_count;
          this.filter_list[2] = response.data.item_type_filter_list_ex;
          this.filter_list[3] = response.data.hattyu_company_filter_list_ex;
          this.filter_list[5] = response.data.cycle_filter_list_ex;
          this.hattyu_tanka_total = response.data.hattyu_tanka_total;
          this.where = response.data.where;
          this.order = response.data.order;
          this.isLoading = false;
        })
    },
    // 在庫 削除
    onDelete: function (zaiko_id) {
      if (confirm('数量を0にしますか？')) {
        axios
          .delete(`/zaiko/del_zaiko/${zaiko_id}`)
          .then(response => {
            (this.data_list = response.data.zaiko_list);
          })
      }
    },
    // 在庫 モーダル(新規、編集) 表示
    onEdit: function (zaiko_id) {
      axios
        .get(`/zaiko/get_zaiko/${zaiko_id}`)
        .then(response => {
          (this.zaiko = response.data.zaiko);
          this.zaiko_plus = 0;
          this.zaiko_minus = 0;
          this.$root.$emit('bv::show::modal', 'modal-zaiko')
        })
    },
    // 商品 新規、編集
    updateZaiko: function (type) {
      axios
        .patch(`/zaiko/update_zaiko`, {
          type: type, zaiko: this.zaiko,
          zaiko_plus: this.zaiko_plus, zaiko_minus: this.zaiko_minus
        })
        .then(response => {
          if (response.data.flg) {
            this.getZaikoList();
            this.$root.$emit('bv::hide::modal', 'modal-zaiko')
          } else {
            alert("在庫数は確保数以上にしてください。")
          }
        })
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getZaikoList();
    },
    // Export
    CsvExport: function () {
      if (true) {
        this.export_flg = true;
        axios
          .patch(`/zaiko/csv_export`, { where: this.where, order: this.order })
          .then(response => {
            //リンク先にダウンロード用リンクを指定する
            const link = document.createElement('a')
            link.download = response.data.file_name
            link.href = response.data.file_dir + response.data.file_name
            link.click()
            this.export_flg = false;
          }).catch((error) => {
            console.error(error, error.response);
            this.export_flg = false;
          });
      }
    }
  }
}  
</script>

<style scoped></style>